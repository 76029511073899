<template>
    <div class="comment-container">
        <div class="comment-input">
            <van-field
                v-model="comment"
                rows="5"
                autosize
                type="textarea"
                placeholder="请输入这一刻的想法…"
                show-word-limit
            />
        </div>
        <div class="comment-upload">
            <van-uploader
                v-model="fileList"
                :after-read="afterRead"
                :max-count="1"
                :before-read="beforeRead"
                style="margin: 0"
            />
        </div>
        <div class="comment-button" @click="setComment">评论</div>
    </div>
</template>

<script>
import { Toast } from "vant";
import { setComment } from "@/service";
export default {
    data() {
        return {
            comment: "",
            linePointList: [],
            fileList: [],
        };
    },
    mounted() {},
    methods: {
        async setComment() {
            try {
                if (this.fileList.length == 0 || !this.comment) {
                    Toast.fail("请填写必要信息");
                    return false;
                }
                const { id = "" } = this.$route.query;
                const fileList = this.fileList.map((item) => item.url);
                const params = {
                    comment: this.comment,
                    commentId: id,
                    imgUrl: fileList.toString(),
                };
                const { data } = await setComment(params);
                this.comment = "";
                this.fileList = [];
                Toast.success("评论成功");
                this.$router.go(-1);
            } catch (err) {
                Toast.fail(err.message || err);
            }
        },
        async afterRead(file) {
            try {
                const sessionId = sessionStorage.getItem("xxl_sso_sessionid");
                file.status = "uploading";
                file.message = "上传中...";
                let data = new FormData();
                data.append("file", file.file);
                const response = await fetch("/api/others/upload", {
                    method: "POST",
                    credentials: "include",
                    body: data,
                    headers: {
                        xxl_sso_sessionid: sessionId,
                    },
                });
                const res = await response.json();
                this.fileList = [{ url: res.msg }];
                console.log(this.fileList, "====response====");
                file.status = "done";
                file.message = "";
            } catch (err) {
                file.status = "failed";
                file.message = "上传失败";
            }
        },
        // 返回布尔值
        beforeRead(file) {
            // if (file.type !== "image/jpeg") {
            //     Toast("请上传 jpg 格式图片");
            //     return false;
            // }
            return true;
        },
        // 返回 Promise
        asyncBeforeRead(file) {
            return new Promise((resolve, reject) => {
                if (file.type !== "image/jpeg") {
                    Toast("请上传 jpg 格式图片");
                    reject();
                } else {
                    let img = new File(["foo"], "bar.jpg", {
                        type: "image/jpeg",
                    });
                    resolve(img);
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@/style/mixin";
@import "@/style/_varibles";

.comment-container {
    padding: 38px;
    @include wh(100%, auto);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .comment-input {
        @include wh(100%, 300px);
        @include sc(24px, $--color-text-secondary);
        background: #ffffff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.17);
        border-radius: 20px;
    }
    .comment-button {
        @include wh(100%, 100px);
        @include fj(center);
        align-items: center;
        background: #b72e32;
        border-radius: 72px;
        font-size: 32px;
        color: #ffffff;
        margin-top: 150px;
    }
    .comment-upload {
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.17);
        margin-top: 50px;
        border-radius: 20px;
    }
}
.van-image {
    overflow: initial;
    img {
        border-radius: 10px;
    }
}
.van-uploader__upload {
    margin: 0;
}
.van-uploader__preview {
    margin: 0;
}
.van-field {
    border-radius: 20px;
}
</style>